import Svg, { Path } from 'react-native-svg'

const heartColor = '#262338'
const binColor = '#A996FF'

export const Logo = ({ style }) => (
  <Svg viewBox="0 0 94 158" fill="none" style={style}>
    <Path d="M76.5 139C71.298 139 67 143.298 67 148.5C67 153.702 71.298 158 76.5 158C81.702 158 86 153.702 86 148.5C86 143.298 81.702 139 76.5 139ZM76.5 153.023C74.0118 153.023 71.9766 150.988 71.9766 148.5C71.9766 146.012 74.0119 143.977 76.5 143.977C78.9881 143.977 81.0234 146.012 81.0234 148.5C81.2506 150.988 79.2137 153.023 76.5 153.023Z" fill={binColor}/>
    <Path d="M77.6478 136.384C79.6892 136.384 81.5043 136.833 83.3195 137.73L84 23.117L81.7306 20.426C77.6478 21.5478 72.4286 20.426 71.5219 20.426H71.2956C53.5998 15.7151 25.0142 24.015 22.2918 26.0333H22.0655C16.1673 28.7243 11.4026 29.1733 8 28.7243L10.494 149.393C10.494 152.533 13.216 155 16.3922 155H66.5304C65.6236 153.205 64.9431 151.187 64.9431 148.944C64.9431 141.991 70.6149 136.384 77.6474 136.384L77.6478 136.384Z" fill={binColor}/>
    <Path d="M88.1277 9.00008C87.6764 9.00008 87.2268 9.2195 86.7755 9.2195H86.0995H2.02817C0.900882 9.2195 0 9.87931 0 10.9795V21.3183C0 21.9781 0.224823 22.4185 0.900883 22.8589C3.15545 24.1785 10.1424 27.258 21.637 22.1991H21.8618C24.7924 20.4391 52.9663 12.3006 70.5465 16.6997H70.7714C71.6722 16.9191 78.2093 18.0193 82.4922 16.6997C83.393 18.9001 85.4227 20.4391 88.127 20.4391C91.5072 20.4391 93.9868 17.7999 93.9868 14.7201C94.2132 11.639 91.5074 9 88.127 9L88.1277 9.00008ZM88.1277 18.0194C86.3243 18.0194 84.9722 16.6998 84.9722 14.9399C84.9722 13.1799 86.3244 11.8603 88.1277 11.8603C89.931 11.8603 91.2831 13.1799 91.2831 14.9399C91.5079 16.4789 89.931 18.0194 88.1277 18.0194Z" fill={binColor}/>
    <Path d="M83.7737 3.21375C83.7737 1.28548 82.1895 0 80.6071 0H11.1666C9.58248 0 8 1.28552 8 3.21375V6H84V3.21375H83.7737Z" fill={binColor}/>
    <Path d="M64.1886 58.7935C61.7702 56.4219 58.4932 55.0852 55.0727 55.0744C51.6523 55.0641 48.3667 56.3808 45.9335 58.7372C45.7772 58.8893 45.6263 59.0458 45.4787 59.2067C45.327 59.0406 45.1693 58.8786 45.0089 58.7221C42.5915 56.3494 39.3142 55.0111 35.8934 55.0001C32.4726 54.9893 29.1865 56.3065 26.7538 58.664C24.341 61.0138 22.9891 64.2134 23.0001 67.5472C22.9933 70.9226 24.3663 74.1602 26.8122 76.5362L44.003 93.3548H44.0026C44.4257 93.768 44.9982 94 45.5956 94C46.1927 94 46.7651 93.768 47.1882 93.3548L64.2466 76.665C66.659 74.3146 68.0109 71.1149 67.9999 67.7807C68.0071 64.4053 66.6341 61.1681 64.1882 58.7927L64.1886 58.7935Z" fill={heartColor}/>
  </Svg>
)
